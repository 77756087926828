@use "css/mixin/app" as *;

.a-formLabel {
  @include form-component-label-initialize;
  display: inline-flex;
  font-family: 'ヒラギノ明朝 Pro','Hiragino Mincho Pro',serif;
  font-size: 16px;
  font-weight: 600;
  .-req{
    font-family: Hiragino Sans, Hiragino Kaku Gothic ProN, Yu Gothic, YuGothic, sans-serif;
    display: inline-block;
    align-self: center;
    min-width: 40px;
    max-height: 22px;
    margin-left: 24px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px;
    background: #D10000;
  }
}
