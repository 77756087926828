@use "css/mixin/app" as *;

.o-formBlockCheckboxButtonSelectInBulk {
  --_grid-columns: 1fr;
  --_column-label: 120px;
  --_column-selectInBulk: 84px;
  display: grid;
  gap: var(--global-form-element-input-gap-y-lg) 40px;
  align-items: flex-start;
  grid-template-columns: var(--_grid-columns);
  @include form-breakpoint-up {
    --_grid-columns: var(--_column-label) var(--_column-selectInBulk) 1fr;
  }

  & > * {
    @include form-stack-container-row-breakpoint-up-common-min-height;
  }

  .o-formBlockCheckboxButtonSelectInBulk__label {
    display: flex;
    align-items: center;
  }

  .o-formBlockCheckboxButtonSelectInBulk__selectBulk {
    display: flex;
    align-items: center;
  }
}
