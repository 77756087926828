@use 'css/abstracts' as *;
.a-formTextLinkAttention {
  font-weight: 700;
  font-size: var(--global-typography-fontSize-default);
  line-height: var(--global-typofgraphy-lineHeight);
  color: var(--global-color-primary);
  text-decoration: underline;

  @include hover {
    text-decoration: none;
  }

  &.-size-default {
    font-size: var(--global-typography-fontSize-default);
    line-height: var(--global-typofgraphy-lineHeight);
  }
  &.-size-lg {
    font-size: var(--global-typography-fontSize-lg);
    line-height: var(--global-typofgraphy-lineHeight);
  }
}
