@use 'css/abstracts' as *;

.a-formSuffixText {
  @include initialize-form-component-typography;
  font-weight: 700;

  //[class*='o-formBlock'] > & {
  //  margin-left: 8px;
  //}
}
