@use 'css/abstracts' as *;

// フォームブロックのコンテナー
.o-formBlockPreviewContainer {
  --_maxw: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: var(--_maxw, 100%);
  height: auto;

  @include formBlockContainer-modifier;
}
