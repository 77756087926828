@use 'css/abstracts' as *;

.a-formGroupTitle {
  @include initialize-form-component-typography;
  padding: 2px 8px 2px 12px;
  display: flex;
  align-items: center;

  border-left: 2px solid currentColor;
  font-weight: 700;
}
