@use 'css/abstracts' as *;

.o-formBlockAccordionCheckbox {
  --accordion-panel-padding-y: 8px;
  @include form-breakpoint-up {
    --accordion-panel-padding-y: 16px;
  }
}

.o-formBlockAccordionCheckbox__head {
  display: flex;
  gap: 20px;
  justify-content: left;
  align-items: center;


  input:checked + & {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--global-form-color-text-contract);

    & > .o-formBlockAccordionCheckbox__toggleIcon {
      background-color: transparent;

      &::before {
        background-color: currentColor;
      }

      &::after {
        background-color: transparent;
      }
    }
  }
}

.o-formBlockAccordionCheckbox__toggleIcon {
  --_icon-size: 14px;
  --_icon-tickness: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid currentColor;
  width: 24px;
  height: 24px;
  color: var(--global-color-red-400);
  transition: all var(--cssui-animation-timing) ease-out;
  background-color: currentColor;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: var(--global-color-white);
  }

  &:before {
    width: var(--_icon-size);
    height: var(--_icon-tickness);
  }

  &::after {
    width: var(--_icon-tickness);
    height: var(--_icon-size);
  }
}

.o-formBlockAccordionCheckbox__title {
  @include initialize-form-component-typography-small;
  font-weight: 700;
}

.o-formBlockAccordionCheckbox__body {
  padding-inline: 28px;
}
