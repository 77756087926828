@use "css/mixin/app" as *;

.m-formBlockInputSuffix {
  display: grid;
  @include initialize-form-input-block-grid-gap;
  grid-template-columns: 1fr max-content;
  align-items: end;

  &.-is-preview {
    align-items: center;
  }

  .a-formInput {
    // width: 148px;
    width: 100px;
    max-width: 100%;
  }

}
