@use 'css/abstracts' as *;

// checkbox項目がn>1以上の表示
.m-formListCheckbox {
  --_grid-columns: repeat(2, 1fr);
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--global-form-element-input-gap-y-lg) var(--global-form-element-input-gap-x-xl);

  @include form-breakpoint-up {
    grid-template-columns: var(--_grid-columns);
    justify-content: space-around;
  }

  &.-grid-fill {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 24px 48px;
    & + .-grid-fill{
      margin-top: 24px;
    }
  }

  &.-grid-vertical {
    @include form-breakpoint-up {
      --_grid-columns: 1fr;
    }

    .m-formListCheckbox__item {
      @include form-breakpoint-up {
        min-height: 0;
      }
    }
  }
  &.-is-preview:not(.-grid-vertical) {
    .m-formListCheckbox__item {
      //@include form-stack-container-row-breakpoint-up-common-min-height;

      &:not(:last-child) {
        &::after {
          content: '/';
          margin-left: var(--global-form-element-input-gap-x-xl);
        }
      }
    }
  }
}

.m-formListCheckbox__item {
  //flex: 0 0 auto;
  //min-width: 0;
  display: flex;
  align-items: center;
}

