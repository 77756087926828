@use 'css/abstracts' as *;

// checkbox項目がn>1以上の表示
.m-formListRadio {
  --_grid-columns: 1fr;
  display: grid;
  grid-template-columns: var(--_grid-columns);
  gap: var(--global-form-element-input-gap-y-lg) var(--global-form-element-input-gap-x-xl);

  &:not([class*="-grid-"]) {
    @include form-breakpoint-up {
      --_grid-columns: repeat(2, auto);
      justify-content: flex-start;
    }
  }

  &.-grid-fill {
    display: flex;
    flex-wrap: wrap;
  }

  &.-grid-vertical {
    @include form-breakpoint-up {
      --_grid-columns: 1fr;
    }

    .m-formListRadio__item {
      @include form-breakpoint-up {
        min-height: 0;
      }
    }
  }
}

.m-formListRadio__item {
  display: flex;
  align-items: center;

  @include form-stack-container-row-breakpoint-up-common-min-height;
}

.m-formListRadio.-is-preview {
  .m-formListRadio__item {
    @include form-stack-container-row-breakpoint-up-common-min-height-remove;
  }
}
