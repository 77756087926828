@use 'css/abstracts' as *;

.m-formBlockHelpMessage {
  @include initialize-form-component-typography;
  background-color: var(--global-form-color-warn-contract);
  padding: var(--global-form-container-inner-gutter);
}

.m-formBlockHelpMessage__heading {
  margin-bottom: 8px;
  text-align: center;
  font-weight: 700;
}

.m-formBlockHelpMessage__content {
  @include layout-max-content-x-center;
}
