@use 'css/abstracts' as *;

.o-formBlockPointMessage {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;

  @include form-breakpoint-up {
    grid-template-columns: max-content 1fr;
  }
}

.o-formBlockPointMessage__content {
  font-weight: 500;
  font-size: var(--global-typography-fontSize-default);
  line-height: var(--global-typofgraphy-lineHeight);
}
