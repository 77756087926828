@use 'css/mixin/app' as *;
// フォーム関係ページ用
.t-form {
	background-color: #fff;

	.site-main {
	}

	.o-formStack {
		margin-inline: auto;

		& + .o-formBlockAction {
			margin-top: 40px;
			@include form-breakpoint-up {
				margin-top: 80px;
			}
		}
	}
}
.p-form-complete {
	.o-formStack__body {
		padding-top: 40px;
		padding-bottom: 24px;
	}
	.text {
    font-family: 'ヒラギノ明朝 Pro','Hiragino Mincho Pro',serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 200%;
		text-align: center;
		& + .text {
			margin-top: 1em;
		}
	}
}

.-prev .a-buttonContainer{
  border-color: #dfddd6;
}
.a-buttonContainer {
	display: block;
	max-width: 400px;
	border: 3px solid var(--white, #a59450);
	.a-btn {
		border: 1px solid var(--white, #fff);
		background: var(--color-key_gold, #a59450);
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
		width: 100%;
		height: 60px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-style: normal;
		color: var(--white, #fff);
		font-weight: 700;
		line-height: 1.2;
		letter-spacing: 0.36px;
		cursor: pointer;
    font-family: 'ヒラギノ明朝 Pro','Hiragino Mincho Pro',serif;
    gap: 8px;
    .-icon{
      display: inline-block;
      width: 16px;
      height: 16px;
      	background-image: url(../img/form/arrow.svg);
      	background-repeat: no-repeat;
      	background-size: contain;
        margin-top: -4px;
    }
		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	display: block;
		// 	width: 16px;
		// 	height: 10px;
		// 	margin-left: 12px;
		// 	background-image: url(../img/common/icon_arrow_wht.svg);
		// 	background-repeat: no-repeat;
		// 	background-size: contain;
		// 	margin: auto;
		// 	top: 0;
		// 	bottom: 0;
		// 	right: 16px;
		// 	transform: rotate(-90deg);
		// }
		&:hover {
			opacity: 0.7;
		}
		&.-gray {
			background: #dfddd6;
		}
		&.-white {
			color: var(--emeralds-green, #d10000);
			border: 2px solid var(--emeralds-green, #d10000);
			background: var(--white, #fff);
			&::after {
				background-image: url(../img/common/icon_arrow_grn.svg);
			}
		}
		&.-iconFixedLeft {
			&::after {
				left: 16px;
				right: auto;
				transform: rotate(90deg);
			}
		}
	}
}
// .a-headerBtn_inner {
// 	display: flex;
// 	height: 40px;
// 	color: #fff;
// 	justify-content: center;
// 	align-items: center;
// 	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
// 		linear-gradient(93.28deg, #ae9553 1.75%, #cfba83 23.2%, #b99b4b 99.83%);
// 	border: 1px solid #000;
// 	border-image: var(--global-gradation_gold);
// 	border-image-slice: 1;
// 	.-marker {
// 		background: var(--global-color-primary);
// 		padding: 2px 10px 4px;
// 		display: block;
// 		margin-right: 8px;
// 		font-size: 14px;
// 	}
// 	.-icon {
// 		position: relative;
// 		width: 16px;
// 		height: 8px;
// 		margin-left: 12px;
// 		background-image: url(../img/common/icon_arrow.svg);
// 		background-repeat: no-repeat;
// 		background-size: contain;
// 	}
// }
