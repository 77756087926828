// [任意] という項目のマークを表示する
.a-formMarkOptional {
  --color-primary: var(--global-form-color-active, currentColor);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  border: 1px solid var(--color-primary);
  border-radius: 2px;
  font-size: var(--global-form-typofgraphy-fontSize-sm, 1rem);
  line-height: var(--global-form-typofgraphy-lineHeight, inherit);
  font-weight: 700;
  background-color: transparent;
  color: var(--color-primary);
}
