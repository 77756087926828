.o-formStackGroup {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  justify-content: left;
  align-items: flex-start;

  &.-gap-lg {
    gap: 24px;
  }
}
