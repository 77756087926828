.o-formBlockCheckboxButtonGroup {
//
}

.o-formBlockCheckboxButtonGroup__head {
  margin-bottom: 8px;
}

.o-formBlockCheckboxButtonGroup__body {

}


.o-formBlockCheckboxPrivacy{
  .a-formRadio__label{
    cursor: pointer;
    .p-form-confirm & {
         cursor: auto;
       }
  }
}