@use 'css/abstracts' as *;

.o-formBlockPresentTheFlow {
  --_icon-w: 40px;
  display: flex;
  justify-content: center;
}

.o-formBlockPresentTheFlow__Flow {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  @include form-breakpoint-up {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.o-formBlockPresentTheFlow__FlowItem {
  //display: flex;
  display: grid;
  grid-template-columns: var(--_icon-w) 1fr;
  gap: 12px;

  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
}

.o-formBlockPresentTheFlow__FlowItemNumber {
  --_size: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--global-color-red-250);
  width: var(--_size);
  height: var(--_size);
  border-radius: 50%;

  > span {
    font-weight: 700;
    color: var(--global-color-primary);
  }
}

.o-formBlockPresentTheFlow__FlowItemText {
  font-weight: 700;
}
