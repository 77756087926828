@use "css/mixin" as *;

.a-formCheckboxButton {
  --_box-size: 20px;
  --_gutter-vertical: 6px;
  --_gutter-horizontal: 12px;
  @include form-element-initialize-variables;
  @include form-component-initialize-style;
  @include form-component-checkbox-disabled-style;
  @include form-complete-checkboxButton-height-style;
  text-overflow: ellipsis;
  font-weight: 700;
  // 22/09/12 :has はFirefox103ではまだ使えない・・・
  // checkedの子要素を持っていたら、コンポーネントルートにCSS変数を設定できるように したかったな～
  // &:has(input:checked) {
  //  --color-primary: var(--global-color-warn);
  //  --color-secondary: var(--global-color-warn-contract);
  //}
  display: inline-flex;

  &.-size-small {
    --_fz: var(--global-form-typofgraphy-fontSize-sm);
    --_gutter-vertical: 2px;
    --_gutter-horizontal: 8px;
  }
}

.a-formCheckboxButton__input {
  //@include form-component-initialize-variables;
  @include form-visibility-hidden;
  order: -1;

  &:checked {
    & + .a-formCheckboxButton__label {
      @include form-checkbox-checked-variables;
    }
  }

  &:focus-visible {
    & + .a-formCheckboxButton__label {
      @include form-outline-initialize;
    }
  }
}

.a-formCheckboxButton__label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--_gutter-vertical) var(--_gutter-horizontal);
  border-radius: 1px;
  background-color: var(--color-secondary);
  color: var(--color-primary);
}


