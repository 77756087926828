@use "css/mixin/app" as *;

.m-formBlockInputZipCode {
  //@include form-layout-block-prefix-input-suffix-container(
  //  $align-items: center
  //);
  display: grid;
  // gap: var(--global-form-element-input-gap-x);
  // grid-template-columns: max-content 1fr;
  align-items: center;
  width: 100%; // NOTE ?

  & > .a-formPrefixText {
    //flex: 0 0 max-content;
    min-width: 0;
  }

  & > .a-formInputZipCode {
    //flex: 1 0 100%;
    min-width: 0;
    width: auto;
    max-width: 100%;
    @include form-breakpoint-extra-small-up {
      // max-width: 12rem;
    }
  }
}
