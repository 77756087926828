@use "css/mixin/core" as *;
@use "css/mixin/app/form" as *;

.a-formSelect {
  --_icon-size: 16px;
  --_gutter-vertical: 14px;
  --_gutter-horizontal: 8px;
  position: relative;
  background-color: var(--global-form-color-text-contract);
}

.a-formSelect__input {
  @include form-element-initialize-variables;
  @include form-component-initialize-style;
  @include reset-select;
  @include form-select-component-initialize;

  // padding: 10px 20px;
  padding: var(--_gutter-vertical) calc(var(--_gutter-horizontal) * 2 + var(--_icon-size)) var(--_gutter-vertical) 20px;
  line-height: 1;
  width: 100%;
  cursor: pointer;

  &:focus-visible {
    @include form-outline-initialize;
  }
  &.-gray{
    color: var(--global-form-color-placeholder);
  }
}

.a-formSelect__icon {
  @include position-vertical-center;
  right: var(--_gutter-horizontal);
  pointer-events: none;
  width: 16px;
    height: 10px;
  background-image: url(../img/common/icon_arrow_select.svg);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
}
