@use 'css/abstracts' as *;

.o-formBlockAction {
  --_button-columns: 1fr; // 長過ぎるラベルに対応するため、mdまで1カラムにします。
  display: flex;
  justify-content: center;

  @include form-breakpoint-narrow-up {
    --_button-columns: repeat(2, 1fr);
  }

  .a-btn {
    font-weight: 700;
  }

  // 「ログインする」機能のボタンのみの場合のパターン
  &.-use-only-login {
    @include form-breakpoint-narrow-up {
      --_button-columns: 1fr;
    }

    .o-formBlockAction__button {
      &.-next {
        justify-content: center;
      }
    }
  }

  // 「次の画面へ進む」機能のボタンのみの場合のパターン
  &.-use-only-next {
    @include form-breakpoint-narrow-up {
      --_button-columns: 1fr;
    }

    .o-formBlockAction__button {
      &.-next {
        justify-content: center;
      }
    }
  }

  // 「～へ戻る」機能のボタンのみの場合のパターン
  &.-use-back-link {
    @include form-breakpoint-narrow-up {
      --_button-columns: 1fr;
    }

    .o-formBlockAction__button {
      &.-back {
        justify-content: center;
      }
    }
  }
}

.o-formBlockAction__buttons {
  flex: 1 1 100%;
  min-width: 0;
  display: grid;
  gap: 24px;
  grid-template-columns: var(--_button-columns);
  justify-content: center;
  align-items: center;
}

.o-formBlockAction__button {
  // `a-btnContainer`を使った配置
  display: flex;
  justify-content: center;

  & > .a-buttonContainer {
    flex: 1 1 auto;
    min-width: 0;
    margin-inline: 0;
  }

  &.-prev {
    @include form-breakpoint-narrow-up {
      order: -1;
      justify-content: right;
    }
  }

  &.-next {
    @include form-breakpoint-narrow-up {
      justify-content: left;
    }
  }
}
