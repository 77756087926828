@use "css/mixin" as *;

.a-formCheckbox {
  --_box-size: 20px;
  @include form-element-initialize-variables;
  @include form-component-initialize-style;
  @include form-component-checkbox-disabled-style;
  display: inline-flex;
  font-weight: 700;

    .o-formBlockContainer {
    @include form-layout-block-prefix-input-suffix-container;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 12px;
    &.-w-sm  .a-formInput{
      width: 240px;
    }
    .a-formInput{
      width: 80px;
    }
    .a-formSuffixText{
      width: auto;
    }
    & + .o-formBlockContainer{
      margin-top: auto !important;
    }
  }

}

.a-formCheckbox__input {
  @include form-element-initialize-variables;
  @include form-visibility-hidden;
  order: -1;

  &:checked {
    @include form-checkbox-checked-variables;

    & + .a-formCheckbox__label {
      &::before {
        background-color: #D10000;
      }

      & > .a-formCheckbox__icon {
        opacity: 1;
        // color: var(--global-color-warn);
      }
    }
  }

  &:focus-visible {
    & + .a-formCheckbox__label {
      &::before {
        @include form-outline-initialize;
      }
    }
  }
}

.a-formCheckbox__label {
  position: relative;
  display: flex;
  align-items: center;
  // gap: 1em;

  gap: 12px;
  cursor: pointer;

  &::before {
    // チェックボックスの箱
    content: "";
    flex: 0 0 var(--_box-size);
    min-width: var(--_box-size);
    width: var(--_box-size);
    height: var(--_box-size);
    border-radius: 1px;
    border: 1px solid var(--_bd-color);
    background-color: var(--_bg-color);
  }

  .a-formCheckbox__icon {
    content: "";
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--_box-size);
    height: var(--_box-size);
    opacity: 0;
    background-image: url(../img/common/icon_checked.svg);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;

    & > .a-icon {
      width: 1em;
      height: 1em;
    }
  }
}


