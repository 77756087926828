@use "css/mixin" as *;

.o-formBlockConsentToRegistration {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  padding: 40px;
  border: 1px solid var(--global-color-gray-300);
  background-color: #fff;
  text-align: center;

  a {
    text-decoration: underline;
    @include hover {
      text-decoration: none;
    }
  }
}

.o-formBlockConsentToRegistration__head {
  @at-root {
    .o-formBlockConsentToRegistration__heading {
      font-size: var(--global-typography-fontSize-lg);
      line-height: var(--global-typofgraphy-lineHeight);
      font-weight: 700;
    }

    .o-formBlockConsentToRegistration__description {
      margin-top: 4px;
      @include initialize-form-component-typography;
      color: var(--global-color-gray-400);
    }
  }
}

.o-formBlockConsentToRegistration__body {
  @include initialize-form-component-typography;
}

.o-formBlockConsentToRegistration__footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}
