@use 'css/abstracts' as *;

.m-formBlockWarningMessage {
  @include initialize-form-component-typography-small;
  padding: var(--global-spacing-1) var(--global-spacing-4);
  border-radius: 4px;
  background-color: var(--global-form-color-warn-contract);
  color: #ED1C24;
  font-family: YuGothic,'Yu Gothic',sans-serif;
  font-weight: 700;
}
