@mixin reset-input {
  appearance: none;
}

@mixin reset-select {
  border: 0;
  appearance: none;
  word-wrap: normal;

  //&::-ms-expand {
  //  display: none;
  //}
  //
  //&:-moz-focusring {
  //  color: transparent;
  //}
}

@mixin placeholder-initialize($color) {
  &::placeholder {
    color: $color;
  }
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}
