@use "css/mixin/app" as *;
// 氏名の2カラムフォーム

.o-formBlockFullName {
  display: grid;
  width: 100%;
  @include initialize-form-input-block-grid-gap;
  grid-template-columns: repeat(2, 1fr);

  .a-formInput {
    max-width: 100%;
    width: 100%;
  }
}
.p-form-confirm .o-formBlockFullName {
  width: auto;
}
.o-formBlockFullName__firstName {
  width: 100%;

}

.o-formBlockFullName__lastName {
width: 100%;
}
