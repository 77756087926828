@use "css/mixin/app" as *;

.m-formBlockBirthday {
  --_grid-columns: 1fr;
  display: grid;
  @include initialize-form-input-block-grid-gap;
  grid-template-columns: var(--_grid-columns);

  @include form-breakpoint-extra-small-down {
    width: 100%;
  }
  @include form-breakpoint-extra-small-up {
    --_grid-columns: repeat(3, max-content);
  }

  input {
    max-width: 100%;
    width: 100%;
  }

  &.-is-preview {
    --_grid-columns: repeat(3, max-content);
  }
}

.m-formBlockBirthday__input {
  @include form-layout-block-prefix-input-suffix-container;
}













