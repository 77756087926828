@use 'css/abstracts' as *;
// # 実装ツリー
// .o-formStackSection
// ├ .o-formStackSection__head
// │  ├ .a-formStackSectionHeading
//    ├ .a-formStackSectionDescription
//    └ .a-formblockwarningmessages
// └ .o-formStackSection__body ... body内には、フォーム本体のみが入る
//    └ .a-formStackContainer

%formStackSectionContentContainer {
  @include form-breakpoint-up {
    padding-inline: var(--global-form-container-inner-gutter);
  }
}

.o-formStackSection {
//   .m-formSectionHeading {
//     margin-bottom: var(--global-form-element-input-gap-y);
//   }

  .m-formSectionDescription {
    margin-top: var(--global-form-element-input-gap-y);
  }

  .o-formBlockPointMessage {
    margin-top: var(--global-form-element-input-gap-y-xl);
  }

  .m-formBlockWarningMessage {
    margin-top: var(--global-form-element-input-gap-y-lg);
  }
}

.o-formStackSection__head {
  margin-bottom: var(--global-form-element-input-gap-y-xl);
  @include form-breakpoint-up {
    margin-bottom: var(--global-form-element-input-gap-y-3xl);
  }
}

.o-formStackSection__headAddition {
  @extend %formStackSectionContentContainer;
}

.o-formStackSection__body {
  //@extend %formStackSectionContentContainer;
  .m-myPageBlockLinkToUpdate {
    margin-bottom: var(--global-form-element-input-gap-y-xl);
  }
}
