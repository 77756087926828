@use 'css/abstracts' as *;
// checkbox項目がn>1以上の表示
.m-formListCheckboxButton {
  //display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(, max-content));
  display: flex;
  flex-wrap: wrap;
  gap: var(--global-form-element-input-gap-y) var(--global-form-element-input-gap-x-xl);

  &.-grid-fill {
    display: flex;
    flex-wrap: wrap;
  }

  &.-is-preview {
    .m-formListCheckboxButton__item {
      @include form-stack-container-row-breakpoint-up-common-min-height-remove;

      &:not(:last-child) {
        &::after {
          content: '/';
          margin-left: var(--global-form-element-input-gap-x-xl);
        }
      }
    }
  }
}

.m-formListCheckboxButton__item {
  flex: 0 0 auto;
  min-width: 0;
  max-width: 100%;
  display: flex;
  align-items: center;

  @include form-stack-container-row-breakpoint-up-common-min-height;
}
