@use 'css/abstracts' as *;

.o-formBlockPeriodOfAtime {
  @include initialize-form-component-typography;
  @include initialize-form-input-block-grid-gap {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: auto;
    justify-content: start;
    align-items: center;

    @include form-breakpoint-up {
      //grid-template-columns: max-content min-content max-content max-content;
      display: flex;
    }
  }
  font-weight: 700;

  // 画面が狭いと、横幅不足になるので1カラムバージョンを用意
  &.-use-with-suffix {
    @include mq($until: xs) {
      grid-template-columns: 1fr;
      justify-content: center;
    }

    .o-formBlockPeriodOfAtime__range,
    .o-formBlockPeriodOfAtime__suffix {
      @include mq($until: xs) {
        padding-inline: 0;
        text-align: left;
      }
    }
  }
}

.o-formBlockPeriodOfAtime__date {
  @include initialize-form-input-block-grid-gap {
    display: flex;
    align-items: flex-end;
  }

  & > input {
    flex: 0 0 auto;
    max-width: max-content;
        max-width: 50px;
  }
  
  & > .a-formInputDateMonth,
  & > .a-formInputDateDay {
    max-width: 50px;
    // max-width: 80px;
  }

  &.-period-start {
    @include form-breakpoint-up {
      margin-right: auto;
      margin-left: 0;
    }
  }

  &.-period-end {
    @include form-breakpoint-up {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.o-formBlockPeriodOfAtime__range {
  // padding-inline: 1em;
  text-align: center;
}

.o-formBlockPeriodOfAtime__suffix {
  flex: 0 0 max-content;
  align-self: flex-end;
}
