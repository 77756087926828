@use "css/mixin/app" as *;
// フォーム全体
.o-formStackContainer {
  --_row-gap-x: 0;
  --_row-gap-y: 24px;
  --_cell-label-w: 280px; // 240px - var(--_cell-gap-x)
  --_cell-body-w: 1fr;
  --_cell-gap-x: var(--global-form-element-input-gap-x);
  --_cell-gap-y: var(--global-form-element-input-gap-y-lg);
  --_label-gap-x: var(--global-form-element-input-gap-x-3xl);

  //display: grid;
  //grid-template-columns: 100%;
  //grid-template-rows: auto;
  display: flex;
  flex-direction: column;
  gap: var(--_row-gap-y) var(--_row-gap-x);

  .o-formBlockContainer {
    & + .o-formBlockContainer {
      margin-top: var(--global-form-element-input-gap-y-xl); // same to
    }
  }

  .a-formTextFieldHint {
    margin-bottom: var(--global-spacing-1);
    margin-left: 8px;
  }

  .text{
    font-size: 16px;
    line-height: 23px;
  }
  .text_link{
   text-decoration: underline;
   &:hover{
    text-decoration: none;
   }
  }

  /**
    非入力フィールド状態の場合
   */
  &.-is-preview {
    label {
      pointer-events: none;
    }

    .o-formStackContainer__rowLabel,
    .o-formStackContainer__rowsTitle,
    .o-formStackContainer__rowBody {
      @include form-stack-container-row-breakpoint-up-common-min-height-remove;
    }

    .o-formStackContainer__rowsBody {
      @include form-breakpoint-narrow-down {
        padding-left: var(--global-form-container-inner-gutter);
      }
    }
  }
}

// __rowsTitleがある場合はこれを選択することになる
.o-formStackContainer__rows {
  display: grid;
  grid-template-columns: 1fr;
  //grid-template-areas: "rows-title row";
  gap: var(--global-form-container-inner-gutter);
  justify-content: left;
  align-items: flex-start;

  @include form-breakpoint-up {
    grid-template-columns: 148px 1fr;
  }
}

%row-container-gap {
  gap: var(--_cell-gap-y) var(--_cell-gap-x);
}

// 繰り返し行
.o-formStackContainer__row {
  @extend %row-container-gap;
  display: grid;
  grid-template-columns: 1fr;


  @include form-breakpoint-up {
    grid-template-columns: var(--_cell-label-w) var(--_cell-body-w);
    justify-content: left;
    align-items: flex-start;
  }

  .o-formStackContainer__rows & {
    @include layout-site-header-full-breakpoint-less {
      grid-template-columns: 1fr;
    }

    .o-formStackContainer__rowLabel {
      @include layout-site-header-full-breakpoint-less {
        @include form-stack-container-row-breakpoint-up-common-min-height-remove;
        padding-left: 0;
      }
    }
  }

  // 上揃え状態にしたいとき
  &.-h-narrow {
    .o-formStackContainer__rowLabel,
    .o-formStackContainer__rowsTitle {
      @include form-stack-container-row-breakpoint-up-common-min-height-remove;
    }
  }

  &.-privacy-policy{
    display: flex;
    .-req{
      margin-left: 0;
    }
    .o-formStackContainer__rowLabel{
      padding-left: 0;
      padding-right: 8px;
    }
  }
}

%row-initialize {
  @include form-stack-container-row-breakpoint-up-common-min-height;
}

.o-formStackContainer__rowsTitle {
  @extend %row-initialize;
  //grid-area: rows-title;
  display: flex;
  align-items: center;
  @include form-breakpoint-up {
    padding-left: var(--global-form-container-inner-gutter); // NOTE @Design ほんとにここが持って良いのか?
  }
}

.o-formStackContainer__rowsBody {
  @extend %row-container-gap;
  display: grid;
  grid-template-columns: 1fr;
  //grid-area: rows-body;
}

.o-formStackContainer__rowLabel {
  @extend %row-initialize;
  display: flex;
  align-items: center;
  gap: 0 var(--_label-gap-x);

  @include form-breakpoint-up {
    padding: 0 var(--global-form-container-inner-gutter);
  }
}

.o-formStackContainer__rowBody {
  &.-hasHint{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    .-w-sm{
      min-width: 240px;
    }
  }
  @extend %row-initialize;
}

