@use 'css/abstracts' as *;

.m-formSectionHeading {
  --_gutter-x: 14px;
  display: flex;
  align-items: stretch;
  gap: 0 var(--_gutter-x);
  font-weight: 700;
font-size: 22px;
line-height: 100%;
border-bottom: 1px solid  #1F1F1F;
position: relative;
padding-bottom: 8px;
font-family: 'ヒラギノ明朝 Pro','Hiragino Mincho Pro',serif;

  &::before {
    content: '';
    width: 200px;
    height: 4px;
   background: #D10000;
   position: absolute;
   bottom: -3px;
   left: 0;
  }
}

.m-formSectionHeading__label {
  // padding: var(--global-spacing-1) var(--_gutter-x) var(--global-spacing-1) 0;
}
