@use 'css/mixin/app' as *;

.a-formTextPreview {
	// min-width: 80px;
	padding: 0 20px;
	@include initialize-form-component-typography;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	color: #111111;

	& > a {
		@include common-link-text-interaction;
	}
}
