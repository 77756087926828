@use 'css/abstracts' as *;

.o-formBlockProgressStepBar {
  --_status-incomplete-color: var(--global-color-gray-350);
  --_status-completed-color: var(--global-color-red-300);
  --_status-active-color: var(--global-color-primary);
  --_step-icon-size: 10px;
  --_bar-color: currentColor;
  --_bar-tickness: 2px;
  --_layout-gap: 6px;
  --_current-color: var(--_status-incomplete-color);
  position: relative;
  z-index: 0;
  // NOTE attr()でdata属性の値を取れるなら、いちいちitem毎にclassを付与しないようにしたい
  //&[data-active-step] {
  //  .o-formBlockProgressStepBar__item {
  //    :nth-child(n+0):nth-child(-n + attr(data-active-step)) {
  //    .o-formBlockProgressStepBar__point {
  //      background-color: var(--_active-color);
  //    }
  //  }
  //  }
  //}
}

.o-formBlockProgressStepBar__list {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--_layout-gap);
}

.o-formBlockProgressStepBar__item {
  flex: 1 1 auto;
  min-width: 0;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--_layout-gap);
  color: var(--_current-color);

  // ラベル上部の円
  &::before {
    content: "";
    border-radius: 50%;
    width: var(--_step-icon-size);
    height: var(--_step-icon-size);
    background-color: currentColor;
  }

  //
  &::after {
    content: '';
    position: absolute;
    top: calc(var(--_step-icon-size) / 2 - var(--_bar-tickness) / 2);
    left: 50%;
    width: calc(100% + var(--_step-icon-size));
    height: var(--_bar-tickness);
    z-index: -1;
    background-color: currentColor;
    color: var(--_bar-color);
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  &.-is-incomplete {
    --_bar-color: var(--_status-incomplete-color);
    --_current-color: var(--_status-incomplete-color);
  }

  &.-is-completed {
    --_bar-color: var(--_status-completed-color);
    --_current-color: var(--_status-completed-color);
  }

  &.-is-active {
    --_bar-color: var(--_status-incomplete-color);
    --_current-color: var(--_status-active-color);
  }
}

.o-formBlockProgressStepBar__label {
  @include initialize-form-component-typography-small;

  font-weight: 700;
  color: currentColor;
}
