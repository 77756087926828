@charset "UTF-8";
:root {
  --global-form-color-text: var(--global-color-text);
  --global-form-color-text-contract: var(--global-color-gray-200);
  --global-form-color-placeholder: var(--global-color-gray-400);
  --global-form-color-warn: var(--global-color-warn);
  --global-form-color-warn-contract: var(--global-color-warn-contract);
  --global-form-color-active: var(--global-color-warn);
  --global-form-color-active-contract: var(--global-color-warn-contract);
  --global-form-typofgraphy-fontSize-xs: 0.625rem;
  --global-form-typofgraphy-fontSize-sm: 0.75rem;
  --global-form-typofgraphy-fontSize: var(--global-typography-fontSize-default);
  --global-form-typofgraphy-lineHeight: 1.5;
  --global-form-typofgraphy-lineHeight-lg: 1.8;
  --global-form-typofgraphy-lineHeight-xl: 2;
  --global-form-container-inner-gutter: 16px;
  --global-form-element-min-height: 42px;
  --global-form-element-input-gap-x: 16px;
  --global-form-element-input-gap-x-lg: 12px;
  --global-form-element-input-gap-x-xl: 16px;
  --global-form-element-input-gap-x-2xl: 20px;
  --global-form-element-input-gap-x-3xl: 24px;
  --global-form-element-input-gap-y: 16px;
  --global-form-element-input-gap-y-lg: 12px;
  --global-form-element-input-gap-y-xl: 16px;
  --global-form-element-input-gap-y-2xl: 20px;
  --global-form-element-input-gap-y-3xl: 24px;
  --global-form-element-input-gap-y-4xl: 28px;
  --global-form-element-input-gap-y-5xl: 32px;
  --global-form-state-readonly-opacity: .5;
  --global-form-state-disabled-opacity: .5;
}

[data-accordion] {
  --cssui-animation-timing: 0.3s;
  --cssui-animation-rotate: rotate(-180deg);
  --accordion-panel-padding-y: 1em;
}

[data-accordion-item] {
  width: 100%;
}

[data-accordion-item] > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

[data-accordion-item] > label {
  cursor: pointer;
}

/* Accordion Item's Label Focus State */
[data-accordion-item] > input:focus + label {
  outline: 2px solid #101010;
  outline: 2px solid highlight;
  outline: 2px solid -webkit-focus-ring-color;
}

/* Hide focus ring if focus comes from a pointer device. */
[data-accordion-item] > input:focus:not(:focus-visible) + label {
  outline: none;
}

[data-accordion-item] > label > svg {
  transition: all var(--cssui-animation-timing) ease-out;
}

[data-accordion-panel] {
  max-height: 0;
  transition: padding var(--cssui-animation-timing) ease;
  visibility: hidden;
  padding-block: 0;
}

[data-accordion-item] > input:checked + label > svg {
  transform: rotate(-180deg);
}

[data-accordion-item] > input:checked ~ [data-accordion-panel] {
  max-height: 100vh;
  visibility: visible;
  padding-block: var(--accordion-panel-padding-y);
}

.no-js img.lazyload {
  display: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.4s;
}

/**
 * どのような構造で配置されていても、左右画面いっぱいに広がるようにする
 */
/**
  全てのフォームコンポーネントの共通機能
  inputに直接付与すること
 */
/**
  input,textarea,select
 */
/**
GMJのフォームの補助テキストは、基本的に「下揃え」
 */
.a-formCheckbox {
  --_box-size: 20px;
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  display: inline-flex;
  font-weight: 700;
}
.a-formCheckbox.-has-error,
.a-formCheckbox .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formCheckbox.-has-error + .a-formSelect__icon,
.a-formCheckbox .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formCheckbox[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formCheckbox[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formCheckbox::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formCheckbox:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formCheckbox.-is-disabled > label, .a-formCheckbox > [type=checkbox]:disabled + label {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formCheckbox .o-formBlockContainer {
  display: flex;
  gap: var(--global-form-element-input-gap-x);
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
}
.a-formCheckbox .o-formBlockContainer.-w-sm .a-formInput {
  width: 240px;
}
.a-formCheckbox .o-formBlockContainer .a-formInput {
  width: 80px;
}
.a-formCheckbox .o-formBlockContainer .a-formSuffixText {
  width: auto;
}
.a-formCheckbox .o-formBlockContainer + .o-formBlockContainer {
  margin-top: auto !important;
}

.a-formCheckbox__input {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  order: -1;
}
.a-formCheckbox__input.-has-error,
.a-formCheckbox__input .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formCheckbox__input.-has-error + .a-formSelect__icon,
.a-formCheckbox__input .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formCheckbox__input[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formCheckbox__input[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formCheckbox__input:checked {
  --color-primary: var(--global-form-color-active);
  --color-secondary: var(--global-form-color-active-contract);
  --_outline-color: var(--color-primary);
}
.a-formCheckbox__input:checked + .a-formCheckbox__label::before {
  background-color: #D10000;
}
.a-formCheckbox__input:checked + .a-formCheckbox__label > .a-formCheckbox__icon {
  opacity: 1;
}
.a-formCheckbox__input:focus-visible + .a-formCheckbox__label::before {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}

.a-formCheckbox__label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.a-formCheckbox__label::before {
  content: "";
  flex: 0 0 var(--_box-size);
  min-width: var(--_box-size);
  width: var(--_box-size);
  height: var(--_box-size);
  border-radius: 1px;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
}
.a-formCheckbox__label .a-formCheckbox__icon {
  content: "";
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--_box-size);
  height: var(--_box-size);
  opacity: 0;
  background-image: url(../img/common/icon_checked.svg);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}
.a-formCheckbox__label .a-formCheckbox__icon > .a-icon {
  width: 1em;
  height: 1em;
}

.a-formCheckboxButton {
  --_box-size: 20px;
  --_gutter-vertical: 6px;
  --_gutter-horizontal: 12px;
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  height: auto;
  text-overflow: ellipsis;
  font-weight: 700;
  display: inline-flex;
}
.a-formCheckboxButton.-has-error,
.a-formCheckboxButton .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formCheckboxButton.-has-error + .a-formSelect__icon,
.a-formCheckboxButton .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formCheckboxButton[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formCheckboxButton[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formCheckboxButton::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formCheckboxButton:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formCheckboxButton.-is-disabled > label, .a-formCheckboxButton > [type=checkbox]:disabled + label {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formCheckboxButton.-size-small {
  --_fz: var(--global-form-typofgraphy-fontSize-sm);
  --_gutter-vertical: 2px;
  --_gutter-horizontal: 8px;
}

.a-formCheckboxButton__input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  order: -1;
}
.a-formCheckboxButton__input:checked + .a-formCheckboxButton__label {
  --color-primary: var(--global-form-color-active);
  --color-secondary: var(--global-form-color-active-contract);
  --_outline-color: var(--color-primary);
}
.a-formCheckboxButton__input:focus-visible + .a-formCheckboxButton__label {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}

.a-formCheckboxButton__label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--_gutter-vertical) var(--_gutter-horizontal);
  border-radius: 1px;
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.a-formRowGroupTitle {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  font-weight: 400;
  display: flex;
  padding: 2px;
  border-bottom: 1px solid currentColor;
  color: var(--global-form-color-text);
}

.a-formGroupTitle {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  padding: 2px 8px 2px 12px;
  display: flex;
  align-items: center;
  border-left: 2px solid currentColor;
  font-weight: 700;
}

.a-formHr {
  border: 1px solid var(--global-color-gray-350);
}

.a-formInput {
  box-sizing: border-box;
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  padding: 10px 20px;
  font-weight: 700;
}
.a-formInput.-has-error,
.a-formInput .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formInput.-has-error + .a-formSelect__icon,
.a-formInput .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formInput[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formInput[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formInput::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formInput:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formInput:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}
.a-formInput:invalid {
  --color-primary: var(--global-color-warn);
  --color-secondary: var(--global-color-warn-contract);
}
.a-formInput:placeholder-shown:invalid {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
}
.a-formInput:focus-within {
  --_bd-color: var(--_bg-color);
}

.a-formInputDateYear {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  padding: 10px 20px;
  font-weight: 700;
}
.a-formInputDateYear.-has-error,
.a-formInputDateYear .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formInputDateYear.-has-error + .a-formSelect__icon,
.a-formInputDateYear .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formInputDateYear[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formInputDateYear[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formInputDateYear::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formInputDateYear:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formInputDateYear:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}
.a-formInputDateYear:invalid {
  --color-primary: var(--global-color-warn);
  --color-secondary: var(--global-color-warn-contract);
}
.a-formInputDateYear:placeholder-shown:invalid {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
}
.a-formInputDateYear:focus-within {
  --_bd-color: var(--_bg-color);
}

.a-formInputDateMonth {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  padding: 10px 20px;
  font-weight: 700;
}
.a-formInputDateMonth.-has-error,
.a-formInputDateMonth .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formInputDateMonth.-has-error + .a-formSelect__icon,
.a-formInputDateMonth .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formInputDateMonth[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formInputDateMonth[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formInputDateMonth::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formInputDateMonth:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formInputDateMonth:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}
.a-formInputDateMonth:invalid {
  --color-primary: var(--global-color-warn);
  --color-secondary: var(--global-color-warn-contract);
}
.a-formInputDateMonth:placeholder-shown:invalid {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
}
.a-formInputDateMonth:focus-within {
  --_bd-color: var(--_bg-color);
}

.a-formInputDateDay {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  padding: 10px 20px;
  font-weight: 700;
}
.a-formInputDateDay.-has-error,
.a-formInputDateDay .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formInputDateDay.-has-error + .a-formSelect__icon,
.a-formInputDateDay .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formInputDateDay[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formInputDateDay[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formInputDateDay::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formInputDateDay:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formInputDateDay:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}
.a-formInputDateDay:invalid {
  --color-primary: var(--global-color-warn);
  --color-secondary: var(--global-color-warn-contract);
}
.a-formInputDateDay:placeholder-shown:invalid {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
}
.a-formInputDateDay:focus-within {
  --_bd-color: var(--_bg-color);
}

.a-formInputFile {
  display: flex;
  align-items: center;
  min-height: var(--global-form-element-min-height);
}

.a-formInputZipCode {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  padding: 10px 20px;
  font-weight: 700;
}
.a-formInputZipCode.-has-error,
.a-formInputZipCode .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formInputZipCode.-has-error + .a-formSelect__icon,
.a-formInputZipCode .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formInputZipCode[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formInputZipCode[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formInputZipCode::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formInputZipCode:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formInputZipCode:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}
.a-formInputZipCode:invalid {
  --color-primary: var(--global-color-warn);
  --color-secondary: var(--global-color-warn-contract);
}
.a-formInputZipCode:placeholder-shown:invalid {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
}
.a-formInputZipCode:focus-within {
  --_bd-color: var(--_bg-color);
}

.a-formLabel {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  font-weight: 400;
  display: inline-flex;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 16px;
  font-weight: 600;
}
.a-formLabel .-req {
  font-family: Hiragino Sans, Hiragino Kaku Gothic ProN, Yu Gothic, YuGothic, sans-serif;
  display: inline-block;
  align-self: center;
  min-width: 40px;
  max-height: 22px;
  margin-left: 24px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 2px 8px;
  background: #D10000;
}

.a-formMarkOptional {
  --color-primary: var(--global-form-color-active, currentColor);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  border: 1px solid var(--color-primary);
  border-radius: 2px;
  font-size: var(--global-form-typofgraphy-fontSize-sm, 1rem);
  line-height: var(--global-form-typofgraphy-lineHeight, inherit);
  font-weight: 700;
  background-color: transparent;
  color: var(--color-primary);
}

.a-formRadio {
  --_box-size: 20px;
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  display: inline-flex;
  font-weight: 700;
}
.a-formRadio.-has-error,
.a-formRadio .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formRadio.-has-error + .a-formSelect__icon,
.a-formRadio .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formRadio[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formRadio[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formRadio::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formRadio:placeholder-shown {
  text-overflow: ellipsis;
}

.a-formRadio__input {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  order: -1;
}
.a-formRadio__input.-has-error,
.a-formRadio__input .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formRadio__input.-has-error + .a-formSelect__icon,
.a-formRadio__input .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formRadio__input[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formRadio__input[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formRadio__input:checked + .a-formRadio__label::after {
  opacity: 1;
}
.a-formRadio__input:focus-visible + .a-formRadio__label::before {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}

.a-formRadio__label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;
}
.a-formRadio__label::before, .a-formRadio__label::after {
  content: "";
  border-radius: 50%;
  margin-top: -0.2em;
}
.a-formRadio__label::before {
  border: 1px solid var(--color-gray_999999, #999);
  flex: 0 0 20px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-color: var(--_bg-color);
}
.a-formRadio__label::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 5px;
  background-color: #D10000;
  opacity: 0;
  box-sizing: border-box;
}
.p-form-confirm .a-formRadio__label::after {
  opacity: 1;
}

.a-formSelect {
  --_icon-size: 16px;
  --_gutter-vertical: 14px;
  --_gutter-horizontal: 8px;
  position: relative;
  background-color: var(--global-form-color-text-contract);
}

.a-formSelect__input {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  border: 0;
  appearance: none;
  word-wrap: normal;
  border: 0;
  background-color: var(--_bg-color);
  font-weight: 700;
  color: var(--_color);
  padding: var(--_gutter-vertical) calc(var(--_gutter-horizontal) * 2 + var(--_icon-size)) var(--_gutter-vertical) 20px;
  line-height: 1;
  width: 100%;
  cursor: pointer;
}
.a-formSelect__input.-has-error,
.a-formSelect__input .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formSelect__input.-has-error + .a-formSelect__icon,
.a-formSelect__input .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formSelect__input[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formSelect__input[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formSelect__input::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formSelect__input:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formSelect__input:not(:focus-visible):invalid {
  --_bg-color: var(--color-secondary);
  color: var(--global-form-color-placeholder);
}
.a-formSelect__input > option {
  color: var(--_color);
}
.a-formSelect__input > option[value=""], .a-formSelect__input > option:first-child {
  color: var(--global-form-color-placeholder);
}
.a-formSelect__input:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}
.a-formSelect__input.-gray {
  color: var(--global-form-color-placeholder);
}

.a-formSelect__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--_gutter-horizontal);
  pointer-events: none;
  width: 16px;
  height: 10px;
  background-image: url(../img/common/icon_arrow_select.svg);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
}

.a-formButtonSelectInBulk {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  height: auto;
  --color-secondary: var(--global-color-gray-400);
  --_bd-color: var(--color-secondary);
  --_outline-color: var(--_color);
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid var(--_bd-color);
  border-radius: 1px;
  color: var(--_color);
  font-weight: 700;
}
.a-formButtonSelectInBulk.-has-error,
.a-formButtonSelectInBulk .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formButtonSelectInBulk.-has-error + .a-formSelect__icon,
.a-formButtonSelectInBulk .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formButtonSelectInBulk[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formButtonSelectInBulk[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formButtonSelectInBulk:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}

.a-formSuffixText {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  font-weight: 700;
}

.a-formTextarea {
  box-sizing: border-box;
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
  --color-accent: var(--global-form-color-text);
  --_bg-color: var(--color-secondary);
  --_bd-color: var(--color-secondary);
  --_color: var(--color-primary);
  --_outline-color: var(--color-primary);
  --_fz: var(--global-form-typofgraphy-fontSize);
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--_color);
  outline-color: var(--_outline-color);
  font-family: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--_bd-color);
  background-color: var(--_bg-color);
  padding: 10px 20px;
  font-weight: 700;
}
.a-formTextarea.-has-error,
.a-formTextarea .-has-error {
  --color-primary: var(--global-form-color-warn);
  --color-secondary: var(--global-form-color-warn-contract);
}
.a-formTextarea.-has-error + .a-formSelect__icon,
.a-formTextarea .-has-error + .a-formSelect__icon {
  background-image: url(../img/common/icon_arrow_select_err.svg);
}
.a-formTextarea[readonly] {
  opacity: var(--global-form-state-readonly-opacity);
}
.a-formTextarea[disabled] {
  cursor: auto;
  opacity: var(--global-form-state-disabled-opacity);
}
.a-formTextarea::placeholder {
  color: var(--global-form-color-placeholder);
}
.a-formTextarea:placeholder-shown {
  text-overflow: ellipsis;
}
.a-formTextarea:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}
.a-formTextarea:invalid {
  --color-primary: var(--global-color-warn);
  --color-secondary: var(--global-color-warn-contract);
}
.a-formTextarea:placeholder-shown:invalid {
  --color-primary: var(--global-form-color-text);
  --color-secondary: var(--global-form-color-text-contract);
}
.a-formTextarea:focus-within {
  --_bd-color: var(--_bg-color);
}
.a-formTextarea:focus-visible {
  outline: 1px solid var(--_outline-color, var(--global-form-color-text));
}
.a-formTextarea::placeholder {
  font-weight: 700;
  color: var(--global-form-color-placeholder);
}

.a-formTextFieldHint {
  font-size: var(--global-typography-fontSize-default);
  line-height: var(--global-typofgraphy-lineHeight);
}

.a-formTextFieldWarning {
  font-size: var(--global-typography-fontSize-sm);
  line-height: var(--global-typofgraphy-lineHeight);
  color: var(--global-form-color-warn);
  font-weight: 700;
}

.a-formTextLinkAttention {
  font-weight: 700;
  font-size: var(--global-typography-fontSize-default);
  line-height: var(--global-typofgraphy-lineHeight);
  color: var(--global-color-primary);
  text-decoration: underline;
}
@media (hover: hover) {
  .a-formTextLinkAttention:hover {
    text-decoration: none;
  }
}
.a-formTextLinkAttention.-size-default {
  font-size: var(--global-typography-fontSize-default);
  line-height: var(--global-typofgraphy-lineHeight);
}
.a-formTextLinkAttention.-size-lg {
  font-size: var(--global-typography-fontSize-lg);
  line-height: var(--global-typofgraphy-lineHeight);
}

.a-formTextMessage {
  font-size: var(--global-typography-fontSize-lg);
  line-height: var(--global-form-typofgraphy-lineHeight);
  font-weight: 700;
}

.a-formTextPreview {
  padding: 0 20px;
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #111111;
}
.a-formTextPreview > a {
  color: var(--global-color-primary);
  text-decoration: underline;
}
@media (hover: hover) {
  .a-formTextPreview > a:hover {
    text-decoration: none;
  }
}

.m-formBlockBirthday {
  --_grid-columns: 1fr;
  display: grid;
  gap: var(--global-form-element-input-gap-y) var(--global-form-element-input-gap-x);
  grid-template-columns: var(--_grid-columns);
}
.p-form-confirm .m-formBlockBirthday {
  gap: var(--global-form-element-input-gap-y) 0;
}
@media (max-width: 29.99em) {
  .m-formBlockBirthday {
    width: 100%;
  }
}
@media (min-width: 30em) {
  .m-formBlockBirthday {
    --_grid-columns: repeat(3, max-content);
  }
}
.m-formBlockBirthday input {
  max-width: 100%;
  width: 100%;
}
.m-formBlockBirthday.-is-preview {
  --_grid-columns: repeat(3, max-content);
}

.m-formBlockBirthday__input {
  display: flex;
  gap: var(--global-form-element-input-gap-x);
  align-items: flex-end;
}

.m-formBlockHelpMessage {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  background-color: var(--global-form-color-warn-contract);
  padding: var(--global-form-container-inner-gutter);
}

.m-formBlockHelpMessage__heading {
  margin-bottom: 8px;
  text-align: center;
  font-weight: 700;
}

.m-formBlockHelpMessage__content {
  margin-inline: auto;
  max-width: max-content;
  width: 100%;
}

.m-formBlockInputSuffix {
  display: grid;
  gap: var(--global-form-element-input-gap-y) var(--global-form-element-input-gap-x);
  grid-template-columns: 1fr max-content;
  align-items: end;
}
.p-form-confirm .m-formBlockInputSuffix {
  gap: var(--global-form-element-input-gap-y) 0;
}
.m-formBlockInputSuffix.-is-preview {
  align-items: center;
}
.m-formBlockInputSuffix .a-formInput {
  width: 100px;
  max-width: 100%;
}

.m-formBlockInputZipCode {
  display: grid;
  align-items: center;
  width: 100%;
}
.m-formBlockInputZipCode > .a-formPrefixText {
  min-width: 0;
}
.m-formBlockInputZipCode > .a-formInputZipCode {
  min-width: 0;
  width: auto;
  max-width: 100%;
}
.m-formBlockTextWarningMessage {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize-sm);
  line-height: var(--global-form-typofgraphy-lineHeight-lg);
  color: var(--global-form-color-warn);
  font-weight: 700;
}

.m-formBlockWarningMessage {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize-sm);
  line-height: var(--global-form-typofgraphy-lineHeight-lg);
  padding: var(--global-spacing-1) var(--global-spacing-4);
  border-radius: 4px;
  background-color: var(--global-form-color-warn-contract);
  color: #ED1C24;
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 700;
}

.m-formListCheckbox {
  --_grid-columns: repeat(2, 1fr);
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--global-form-element-input-gap-y-lg) var(--global-form-element-input-gap-x-xl);
}
@media (min-width: 60em) {
  .m-formListCheckbox {
    grid-template-columns: var(--_grid-columns);
    justify-content: space-around;
  }
}
.m-formListCheckbox.-grid-fill {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 24px 48px;
}
.m-formListCheckbox.-grid-fill + .-grid-fill {
  margin-top: 24px;
}
@media (min-width: 60em) {
  .m-formListCheckbox.-grid-vertical {
    --_grid-columns: 1fr;
  }
}
@media (min-width: 60em) {
  .m-formListCheckbox.-grid-vertical .m-formListCheckbox__item {
    min-height: 0;
  }
}
.m-formListCheckbox.-is-preview:not(.-grid-vertical) .m-formListCheckbox__item:not(:last-child)::after {
  content: "/";
  margin-left: var(--global-form-element-input-gap-x-xl);
}

.m-formListCheckbox__item {
  display: flex;
  align-items: center;
}

.m-formListCheckboxButton {
  display: flex;
  flex-wrap: wrap;
  gap: var(--global-form-element-input-gap-y) var(--global-form-element-input-gap-x-xl);
}
.m-formListCheckboxButton.-grid-fill {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 60em) {
  .m-formListCheckboxButton.-is-preview .m-formListCheckboxButton__item {
    min-height: 0;
  }
}
.m-formListCheckboxButton.-is-preview .m-formListCheckboxButton__item:not(:last-child)::after {
  content: "/";
  margin-left: var(--global-form-element-input-gap-x-xl);
}

.m-formListCheckboxButton__item {
  flex: 0 0 auto;
  min-width: 0;
  max-width: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 60em) {
  .m-formListCheckboxButton__item {
    min-height: var(--global-form-element-min-height);
  }
}

.m-formListRadio {
  --_grid-columns: 1fr;
  display: grid;
  grid-template-columns: var(--_grid-columns);
  gap: var(--global-form-element-input-gap-y-lg) var(--global-form-element-input-gap-x-xl);
}
@media (min-width: 60em) {
  .m-formListRadio:not([class*=-grid-]) {
    --_grid-columns: repeat(2, auto);
    justify-content: flex-start;
  }
}
.m-formListRadio.-grid-fill {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 60em) {
  .m-formListRadio.-grid-vertical {
    --_grid-columns: 1fr;
  }
}
@media (min-width: 60em) {
  .m-formListRadio.-grid-vertical .m-formListRadio__item {
    min-height: 0;
  }
}

.m-formListRadio__item {
  display: flex;
  align-items: center;
}
@media (min-width: 60em) {
  .m-formListRadio__item {
    min-height: var(--global-form-element-min-height);
  }
}

@media (min-width: 60em) {
  .m-formListRadio.-is-preview .m-formListRadio__item {
    min-height: 0;
  }
}

.m-formSectionDescription {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
}
.m-formSectionDescription a {
  color: var(--global-color-primary);
  text-decoration: underline;
}
@media (hover: hover) {
  .m-formSectionDescription a:hover {
    text-decoration: none;
  }
}

.m-formSectionHeading {
  --_gutter-x: 14px;
  display: flex;
  align-items: stretch;
  gap: 0 var(--_gutter-x);
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  border-bottom: 1px solid #1F1F1F;
  position: relative;
  padding-bottom: 8px;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
}
.m-formSectionHeading::before {
  content: "";
  width: 200px;
  height: 4px;
  background: #D10000;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.o-formBlockAccordionCheckbox {
  --accordion-panel-padding-y: 8px;
}
@media (min-width: 60em) {
  .o-formBlockAccordionCheckbox {
    --accordion-panel-padding-y: 16px;
  }
}

.o-formBlockAccordionCheckbox__head {
  display: flex;
  gap: 20px;
  justify-content: left;
  align-items: center;
}
input:checked + .o-formBlockAccordionCheckbox__head {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--global-form-color-text-contract);
}
input:checked + .o-formBlockAccordionCheckbox__head > .o-formBlockAccordionCheckbox__toggleIcon {
  background-color: transparent;
}
input:checked + .o-formBlockAccordionCheckbox__head > .o-formBlockAccordionCheckbox__toggleIcon::before {
  background-color: currentColor;
}
input:checked + .o-formBlockAccordionCheckbox__head > .o-formBlockAccordionCheckbox__toggleIcon::after {
  background-color: transparent;
}

.o-formBlockAccordionCheckbox__toggleIcon {
  --_icon-size: 14px;
  --_icon-tickness: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid currentColor;
  width: 24px;
  height: 24px;
  color: var(--global-color-red-400);
  transition: all var(--cssui-animation-timing) ease-out;
  background-color: currentColor;
}
.o-formBlockAccordionCheckbox__toggleIcon::before, .o-formBlockAccordionCheckbox__toggleIcon::after {
  content: "";
  position: absolute;
  background-color: var(--global-color-white);
}
.o-formBlockAccordionCheckbox__toggleIcon:before {
  width: var(--_icon-size);
  height: var(--_icon-tickness);
}
.o-formBlockAccordionCheckbox__toggleIcon::after {
  width: var(--_icon-tickness);
  height: var(--_icon-size);
}

.o-formBlockAccordionCheckbox__title {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize-sm);
  line-height: var(--global-form-typofgraphy-lineHeight-lg);
  font-weight: 700;
}

.o-formBlockAccordionCheckbox__body {
  padding-inline: 28px;
}

.o-formBlockCheckboxButtonSelectInBulk {
  --_grid-columns: 1fr;
  --_column-label: 120px;
  --_column-selectInBulk: 84px;
  display: grid;
  gap: var(--global-form-element-input-gap-y-lg) 40px;
  align-items: flex-start;
  grid-template-columns: var(--_grid-columns);
}
@media (min-width: 60em) {
  .o-formBlockCheckboxButtonSelectInBulk {
    --_grid-columns: var(--_column-label) var(--_column-selectInBulk) 1fr;
  }
}
@media (min-width: 60em) {
  .o-formBlockCheckboxButtonSelectInBulk > * {
    min-height: var(--global-form-element-min-height);
  }
}
.o-formBlockCheckboxButtonSelectInBulk .o-formBlockCheckboxButtonSelectInBulk__label {
  display: flex;
  align-items: center;
}
.o-formBlockCheckboxButtonSelectInBulk .o-formBlockCheckboxButtonSelectInBulk__selectBulk {
  display: flex;
  align-items: center;
}

.o-formBlockAction {
  --_button-columns: 1fr;
  display: flex;
  justify-content: center;
}
@media (min-width: 48em) {
  .o-formBlockAction {
    --_button-columns: repeat(2, 1fr);
  }
}
.o-formBlockAction .a-btn {
  font-weight: 700;
}
@media (min-width: 48em) {
  .o-formBlockAction.-use-only-login {
    --_button-columns: 1fr;
  }
}
.o-formBlockAction.-use-only-login .o-formBlockAction__button.-next {
  justify-content: center;
}
@media (min-width: 48em) {
  .o-formBlockAction.-use-only-next {
    --_button-columns: 1fr;
  }
}
.o-formBlockAction.-use-only-next .o-formBlockAction__button.-next {
  justify-content: center;
}
@media (min-width: 48em) {
  .o-formBlockAction.-use-back-link {
    --_button-columns: 1fr;
  }
}
.o-formBlockAction.-use-back-link .o-formBlockAction__button.-back {
  justify-content: center;
}

.o-formBlockAction__buttons {
  flex: 1 1 100%;
  min-width: 0;
  display: grid;
  gap: 24px;
  grid-template-columns: var(--_button-columns);
  justify-content: center;
  align-items: center;
}

.o-formBlockAction__button {
  display: flex;
  justify-content: center;
}
.o-formBlockAction__button > .a-buttonContainer {
  flex: 1 1 auto;
  min-width: 0;
  margin-inline: 0;
}
@media (min-width: 48em) {
  .o-formBlockAction__button.-prev {
    order: -1;
    justify-content: right;
  }
}
@media (min-width: 48em) {
  .o-formBlockAction__button.-next {
    justify-content: left;
  }
}

.o-formBlockAdditionalMessage {
  font-size: var(--global-form-typofgraphy-fontSize-sm);
  line-height: var(--global-form-typofgraphy-lineHeight-xl);
  font-weight: 500;
}

.o-formBlockCheckboxButtonGroup__head {
  margin-bottom: 8px;
}

.o-formBlockCheckboxPrivacy .a-formRadio__label {
  cursor: pointer;
}
.p-form-confirm .o-formBlockCheckboxPrivacy .a-formRadio__label {
  cursor: auto;
}

.o-formBlockContainer {
  --_maxw: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: var(--_maxw, 100%);
  min-height: var(--global-form-element-min-height);
  height: auto;
  font-family: YuGothic, "Yu Gothic", sans-serif;
}
.o-formBlockContainer > [class*=a-form] {
  max-width: 100%;
  width: 100%;
}
.o-formBlockContainer.-h-narrow {
  min-height: 0;
}
@media (min-width: 60em) {
  .o-formBlockContainer.-w-xs {
    --_maxw: 120px;
  }
}
@media (min-width: 60em) {
  .o-formBlockContainer.-w-sm {
    --_maxw: 240px;
  }
}
@media (min-width: 60em) {
  .o-formBlockContainer.-w-md {
    --_maxw: 496px;
  }
}
@media (min-width: 60em) {
  .o-formBlockContainer.-w-md2 {
    --_maxw: 424px;
  }
}
@media (min-width: 60em) {
  .o-formBlockContainer.-w-lg {
    --_maxw: 520px;
  }
}
@media (min-width: 60em) {
  .o-formBlockContainer.-w-full {
    --_maxw: 100%;
  }
}

.o-formBlockPreviewContainer {
  --_maxw: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: var(--_maxw, 100%);
  height: auto;
}
.o-formBlockPreviewContainer > [class*=a-form] {
  max-width: 100%;
  width: 100%;
}
.o-formBlockPreviewContainer.-h-narrow {
  min-height: 0;
}
@media (min-width: 60em) {
  .o-formBlockPreviewContainer.-w-xs {
    --_maxw: 120px;
  }
}
@media (min-width: 60em) {
  .o-formBlockPreviewContainer.-w-sm {
    --_maxw: 240px;
  }
}
@media (min-width: 60em) {
  .o-formBlockPreviewContainer.-w-md {
    --_maxw: 496px;
  }
}
@media (min-width: 60em) {
  .o-formBlockPreviewContainer.-w-md2 {
    --_maxw: 424px;
  }
}
@media (min-width: 60em) {
  .o-formBlockPreviewContainer.-w-lg {
    --_maxw: 520px;
  }
}
@media (min-width: 60em) {
  .o-formBlockPreviewContainer.-w-full {
    --_maxw: 100%;
  }
}

.o-formBlockConsentToRegistration {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  padding: 40px;
  border: 1px solid var(--global-color-gray-300);
  background-color: #fff;
  text-align: center;
}
.o-formBlockConsentToRegistration a {
  text-decoration: underline;
}
@media (hover: hover) {
  .o-formBlockConsentToRegistration a:hover {
    text-decoration: none;
  }
}

.o-formBlockConsentToRegistration__heading {
  font-size: var(--global-typography-fontSize-lg);
  line-height: var(--global-typofgraphy-lineHeight);
  font-weight: 700;
}

.o-formBlockConsentToRegistration__description {
  margin-top: 4px;
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  color: var(--global-color-gray-400);
}

.o-formBlockConsentToRegistration__body {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
}

.o-formBlockConsentToRegistration__footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

.o-formBlockFullName {
  display: grid;
  width: 100%;
  gap: var(--global-form-element-input-gap-y) var(--global-form-element-input-gap-x);
  grid-template-columns: repeat(2, 1fr);
}
.p-form-confirm .o-formBlockFullName {
  gap: var(--global-form-element-input-gap-y) 0;
}
.o-formBlockFullName .a-formInput {
  max-width: 100%;
  width: 100%;
}

.p-form-confirm .o-formBlockFullName {
  width: auto;
}

.o-formBlockFullName__firstName {
  width: 100%;
}

.o-formBlockFullName__lastName {
  width: 100%;
}

.o-formBlockPeriodOfAtime {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  gap: var(--global-form-element-input-gap-y) var(--global-form-element-input-gap-x);
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: auto;
  justify-content: start;
  align-items: center;
  font-weight: 700;
}
.p-form-confirm .o-formBlockPeriodOfAtime {
  gap: var(--global-form-element-input-gap-y) 0;
}
@media (min-width: 60em) {
  .o-formBlockPeriodOfAtime {
    display: flex;
  }
}
@media (max-width: 29.99em) {
  .o-formBlockPeriodOfAtime.-use-with-suffix {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
@media (max-width: 29.99em) {
  .o-formBlockPeriodOfAtime.-use-with-suffix .o-formBlockPeriodOfAtime__range,
.o-formBlockPeriodOfAtime.-use-with-suffix .o-formBlockPeriodOfAtime__suffix {
    padding-inline: 0;
    text-align: left;
  }
}

.o-formBlockPeriodOfAtime__date {
  gap: var(--global-form-element-input-gap-y) var(--global-form-element-input-gap-x);
  display: flex;
  align-items: flex-end;
}
.p-form-confirm .o-formBlockPeriodOfAtime__date {
  gap: var(--global-form-element-input-gap-y) 0;
}
.o-formBlockPeriodOfAtime__date > input {
  flex: 0 0 auto;
  max-width: max-content;
  max-width: 50px;
}
.o-formBlockPeriodOfAtime__date > .a-formInputDateMonth, .o-formBlockPeriodOfAtime__date > .a-formInputDateDay {
  max-width: 50px;
}
@media (min-width: 60em) {
  .o-formBlockPeriodOfAtime__date.-period-start {
    margin-right: auto;
    margin-left: 0;
  }
}
@media (min-width: 60em) {
  .o-formBlockPeriodOfAtime__date.-period-end {
    margin-right: 0;
    margin-left: auto;
  }
}

.o-formBlockPeriodOfAtime__range {
  text-align: center;
}

.o-formBlockPeriodOfAtime__suffix {
  flex: 0 0 max-content;
  align-self: flex-end;
}

.o-formBlockPointMessage {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
}
@media (min-width: 60em) {
  .o-formBlockPointMessage {
    grid-template-columns: max-content 1fr;
  }
}

.o-formBlockPointMessage__content {
  font-weight: 500;
  font-size: var(--global-typography-fontSize-default);
  line-height: var(--global-typofgraphy-lineHeight);
}

.o-formBlockPresentTheFlow {
  --_icon-w: 40px;
  display: flex;
  justify-content: center;
}

.o-formBlockPresentTheFlow__Flow {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}
@media (min-width: 60em) {
  .o-formBlockPresentTheFlow__Flow {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.o-formBlockPresentTheFlow__FlowItem {
  display: grid;
  grid-template-columns: var(--_icon-w) 1fr;
  gap: 12px;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
}

.o-formBlockPresentTheFlow__FlowItemNumber {
  --_size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--global-color-red-250);
  width: var(--_size);
  height: var(--_size);
  border-radius: 50%;
}
.o-formBlockPresentTheFlow__FlowItemNumber > span {
  font-weight: 700;
  color: var(--global-color-primary);
}

.o-formBlockPresentTheFlow__FlowItemText {
  font-weight: 700;
}

.o-formBlockProgressStepBar {
  --_status-incomplete-color: var(--global-color-gray-350);
  --_status-completed-color: var(--global-color-red-300);
  --_status-active-color: var(--global-color-primary);
  --_step-icon-size: 10px;
  --_bar-color: currentColor;
  --_bar-tickness: 2px;
  --_layout-gap: 6px;
  --_current-color: var(--_status-incomplete-color);
  position: relative;
  z-index: 0;
}

.o-formBlockProgressStepBar__list {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--_layout-gap);
}

.o-formBlockProgressStepBar__item {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--_layout-gap);
  color: var(--_current-color);
}
.o-formBlockProgressStepBar__item::before {
  content: "";
  border-radius: 50%;
  width: var(--_step-icon-size);
  height: var(--_step-icon-size);
  background-color: currentColor;
}
.o-formBlockProgressStepBar__item::after {
  content: "";
  position: absolute;
  top: calc(var(--_step-icon-size) / 2 - var(--_bar-tickness) / 2);
  left: 50%;
  width: calc(100% + var(--_step-icon-size));
  height: var(--_bar-tickness);
  z-index: -1;
  background-color: currentColor;
  color: var(--_bar-color);
}
.o-formBlockProgressStepBar__item:last-child::after {
  content: none;
}
.o-formBlockProgressStepBar__item.-is-incomplete {
  --_bar-color: var(--_status-incomplete-color);
  --_current-color: var(--_status-incomplete-color);
}
.o-formBlockProgressStepBar__item.-is-completed {
  --_bar-color: var(--_status-completed-color);
  --_current-color: var(--_status-completed-color);
}
.o-formBlockProgressStepBar__item.-is-active {
  --_bar-color: var(--_status-incomplete-color);
  --_current-color: var(--_status-active-color);
}

.o-formBlockProgressStepBar__label {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize-sm);
  line-height: var(--global-form-typofgraphy-lineHeight-lg);
  font-weight: 700;
  color: currentColor;
}

.o-formStack {
  --_space-y: 40px;
  --_body-maxw: 640px;
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "step" "header" "body" "footer";
}
.o-formStack .o-formStackSection + .o-formStackSection {
  margin-top: 48px;
}
@media (min-width: 60em) {
  .o-formStack .o-formStackSection + .o-formStackSection {
    margin-top: 80px;
  }
}
.o-formStack .a-formHr {
  margin-block: var(--global-form-element-input-gap-x-3xl);
}
.o-formStack .o-formStackUserAccount {
  margin-inline: auto;
  max-width: 800px;
}
.o-formStack .o-formBlockPresentTheFlow {
  margin-inline: auto;
  max-width: 708px;
}
.o-formStack.-bg-transparent {
  background-color: transparent;
}
@media (min-width: 60em) {
  .o-formStack.-w-body-768 {
    --_body-maxw: 768px;
  }
}

.o-formStack__step {
  grid-area: step;
  margin-inline: auto;
  margin-bottom: 24px;
  max-width: 768px;
  width: 100%;
}
@media (min-width: 60em) {
  .o-formStack__step {
    margin-bottom: 48px;
  }
}

.o-formStack__head {
  margin-inline: auto;
  margin-bottom: var(--_space-y);
  grid-area: header;
  max-width: var(--_body-maxw);
  width: 100%;
}
.o-formStack__head .m-formBlockHelpMessage {
  margin-top: 24px;
}
.o-formStack__title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.25rem;
  line-height: var(--global-form-typofgraphy-lineHeight);
  font-weight: 700;
}
@media (min-width: 60em) {
  .o-formStack__title {
    font-size: 1.5rem;
  }
}
.o-formStack__title + * {
  margin-top: var(--_space-y);
}

.o-formStack__heading {
  margin-inline: auto;
  max-width: max-content;
  width: 100%;
  font-size: 1rem;
  line-height: var(--global-form-typofgraphy-lineHeight);
  font-weight: 700;
}
.o-formStack__heading:first-child + * {
  margin-top: var(--_space-y);
}
.o-formStack__heading + .o-formStack__headDescription {
  margin-top: 8px;
}
.o-formStack__heading.-size-small {
  font-size: 0.875rem;
}
@media (min-width: 60em) {
  .o-formStack__heading.-size-small {
    font-size: 1rem;
  }
}
.o-formStack__heading.-size-large {
  font-size: 1.25rem;
}
@media (min-width: 60em) {
  .o-formStack__heading.-size-large {
    font-size: 1.5rem;
  }
}

.o-formStack__headDescription {
  word-break: break-all;
  font-size: var(--global-form-typofgraphy-fontSize);
  line-height: var(--global-form-typofgraphy-lineHeight);
  margin-inline: auto;
  max-width: max-content;
  width: 100%;
}

.o-formStack__body {
  margin-inline: auto;
  grid-area: body;
  width: 100%;
}

.o-formStack__footer {
  grid-area: footer;
  margin-top: var(--_space-y);
  margin-inline: auto;
  max-width: 800px;
  width: 100%;
}
@media (min-width: 769px) {
  .o-formStack__footer {
    margin-top: 80px;
  }
}

.o-formStackGroup {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  justify-content: left;
  align-items: flex-start;
}
.o-formStackGroup.-gap-lg {
  gap: 24px;
}

@media (min-width: 60em) {
  .o-formStackSection__headAddition {
    padding-inline: var(--global-form-container-inner-gutter);
  }
}

.o-formStackSection .m-formSectionDescription {
  margin-top: var(--global-form-element-input-gap-y);
}
.o-formStackSection .o-formBlockPointMessage {
  margin-top: var(--global-form-element-input-gap-y-xl);
}
.o-formStackSection .m-formBlockWarningMessage {
  margin-top: var(--global-form-element-input-gap-y-lg);
}

.o-formStackSection__head {
  margin-bottom: var(--global-form-element-input-gap-y-xl);
}
@media (min-width: 60em) {
  .o-formStackSection__head {
    margin-bottom: var(--global-form-element-input-gap-y-3xl);
  }
}

.o-formStackSection__body .m-myPageBlockLinkToUpdate {
  margin-bottom: var(--global-form-element-input-gap-y-xl);
}

.o-formStackContainer {
  --_row-gap-x: 0;
  --_row-gap-y: 24px;
  --_cell-label-w: 280px;
  --_cell-body-w: 1fr;
  --_cell-gap-x: var(--global-form-element-input-gap-x);
  --_cell-gap-y: var(--global-form-element-input-gap-y-lg);
  --_label-gap-x: var(--global-form-element-input-gap-x-3xl);
  display: flex;
  flex-direction: column;
  gap: var(--_row-gap-y) var(--_row-gap-x);
  /**
    非入力フィールド状態の場合
   */
}
.o-formStackContainer .o-formBlockContainer + .o-formBlockContainer {
  margin-top: var(--global-form-element-input-gap-y-xl);
}
.o-formStackContainer .a-formTextFieldHint {
  margin-bottom: var(--global-spacing-1);
  margin-left: 8px;
}
.o-formStackContainer .text {
  font-size: 16px;
  line-height: 23px;
}
.o-formStackContainer .text_link {
  text-decoration: underline;
}
.o-formStackContainer .text_link:hover {
  text-decoration: none;
}
.o-formStackContainer.-is-preview label {
  pointer-events: none;
}
@media (min-width: 60em) {
  .o-formStackContainer.-is-preview .o-formStackContainer__rowLabel,
.o-formStackContainer.-is-preview .o-formStackContainer__rowsTitle,
.o-formStackContainer.-is-preview .o-formStackContainer__rowBody {
    min-height: 0;
  }
}
@media (max-width: 47.99em) {
  .o-formStackContainer.-is-preview .o-formStackContainer__rowsBody {
    padding-left: var(--global-form-container-inner-gutter);
  }
}

.o-formStackContainer__rows {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--global-form-container-inner-gutter);
  justify-content: left;
  align-items: flex-start;
}
@media (min-width: 60em) {
  .o-formStackContainer__rows {
    grid-template-columns: 148px 1fr;
  }
}

.o-formStackContainer__rowsBody, .o-formStackContainer__row {
  gap: var(--_cell-gap-y) var(--_cell-gap-x);
}

.o-formStackContainer__row {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 60em) {
  .o-formStackContainer__row {
    grid-template-columns: var(--_cell-label-w) var(--_cell-body-w);
    justify-content: left;
    align-items: flex-start;
  }
}
@media (max-width: 74.99em) {
  .o-formStackContainer__rows .o-formStackContainer__row {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 74.99em) {
  .o-formStackContainer__rows .o-formStackContainer__row .o-formStackContainer__rowLabel {
    padding-left: 0;
  }
}
@media (max-width: 74.99em) and (min-width: 60em) {
  .o-formStackContainer__rows .o-formStackContainer__row .o-formStackContainer__rowLabel {
    min-height: 0;
  }
}
@media (min-width: 60em) {
  .o-formStackContainer__row.-h-narrow .o-formStackContainer__rowLabel,
.o-formStackContainer__row.-h-narrow .o-formStackContainer__rowsTitle {
    min-height: 0;
  }
}
.o-formStackContainer__row.-privacy-policy {
  display: flex;
}
.o-formStackContainer__row.-privacy-policy .-req {
  margin-left: 0;
}
.o-formStackContainer__row.-privacy-policy .o-formStackContainer__rowLabel {
  padding-left: 0;
  padding-right: 8px;
}

@media (min-width: 60em) {
  .o-formStackContainer__rowBody, .o-formStackContainer__rowLabel, .o-formStackContainer__rowsTitle {
    min-height: var(--global-form-element-min-height);
  }
}

.o-formStackContainer__rowsTitle {
  display: flex;
  align-items: center;
}
@media (min-width: 60em) {
  .o-formStackContainer__rowsTitle {
    padding-left: var(--global-form-container-inner-gutter);
  }
}

.o-formStackContainer__rowsBody {
  display: grid;
  grid-template-columns: 1fr;
}

.o-formStackContainer__rowLabel {
  display: flex;
  align-items: center;
  gap: 0 var(--_label-gap-x);
}
@media (min-width: 60em) {
  .o-formStackContainer__rowLabel {
    padding: 0 var(--global-form-container-inner-gutter);
  }
}

.o-formStackContainer__rowBody.-hasHint {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.o-formStackContainer__rowBody.-hasHint .-w-sm {
  min-width: 240px;
}

.o-formStackEncourageRegistration {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
}

.o-formStackEncourageRegistration__heading {
  font-weight: 700;
  text-align: center;
}

.o-formStackEncourageRegistration__actions {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
}

.o-formStackEncourageRegistration__forgotPassword {
  text-align: center;
}

.o-formStackUserAccount {
  --_inner-maxw: 376px;
  padding: 40px 22px;
  border: 2px solid var(--global-color-gray-400);
  border-radius: 2px;
}
@media (min-width: 60em) {
  .o-formStackUserAccount {
    --_inner-maxw: 616px;
  }
}

.o-formStackUserAccount__inner {
  margin-inline: auto;
  max-width: var(--_inner-maxw, 100%);
}

.t-form {
  background-color: #fff;
}
.t-form .o-formStack {
  margin-inline: auto;
}
.t-form .o-formStack + .o-formBlockAction {
  margin-top: 40px;
}
@media (min-width: 60em) {
  .t-form .o-formStack + .o-formBlockAction {
    margin-top: 80px;
  }
}

.p-form-complete .o-formStack__body {
  padding-top: 40px;
  padding-bottom: 24px;
}
.p-form-complete .text {
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 200%;
  text-align: center;
}
.p-form-complete .text + .text {
  margin-top: 1em;
}

.-prev .a-buttonContainer {
  border-color: #dfddd6;
}

.a-buttonContainer {
  display: block;
  max-width: 400px;
  border: 3px solid var(--white, #a59450);
}
.a-buttonContainer .a-btn {
  border: 1px solid var(--white, #fff);
  background: var(--color-key_gold, #a59450);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  color: var(--white, #fff);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.36px;
  cursor: pointer;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  gap: 8px;
}
.a-buttonContainer .a-btn .-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(../img/form/arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -4px;
}
.a-buttonContainer .a-btn:hover {
  opacity: 0.7;
}
.a-buttonContainer .a-btn.-gray {
  background: #dfddd6;
}
.a-buttonContainer .a-btn.-white {
  color: var(--emeralds-green, #d10000);
  border: 2px solid var(--emeralds-green, #d10000);
  background: var(--white, #fff);
}
.a-buttonContainer .a-btn.-white::after {
  background-image: url(../img/common/icon_arrow_grn.svg);
}
.a-buttonContainer .a-btn.-iconFixedLeft::after {
  left: 16px;
  right: auto;
  transform: rotate(90deg);
}

.o-formGlobalHeading {
  width: 100%;
  height: 260px;
  background: url(../img/form/bg_main.jpg) no-repeat center top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (min-width: 769px) {
  .o-formGlobalHeading {
    background: url(../img/form/bg_main_pc.jpg) no-repeat center top;
    background-size: cover;
  }
}
.o-formGlobalHeading > div {
  width: 100%;
  max-width: 456px;
  padding: 0 24px;
}
.o-formGlobalHeading h2 {
  color: var(--color-white, #fff);
  text-align: center;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 47.6px */
}
@media (min-width: 769px) {
  .o-formGlobalHeading h2 {
    font-size: 34px;
  }
}
.o-formGlobalHeading span {
  margin-top: 10px;
  display: inline-flex;
  padding: 2px 20px 0px 20px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: var(--color-key_gold, #a59450);
  color: var(--color-white, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 18px */
}
.o-formGlobalHeading .-line {
  display: block;
  width: 100%;
  max-width: 360px;
  height: 4px;
  margin: 12px auto 0;
  border-radius: 1px;
  background: var(--green, #0a7b5c);
  overflow: hidden;
}

.o-flowArea .intro {
  font-weight: 400;
  line-height: 200%;
  margin-top: 24px;
  font-size: 14px;
}
@media (min-width: 769px) {
  .o-flowArea .intro {
    font-size: 15px;
    margin-top: 80px;
  }
}
.o-flowArea .list-step,
.o-flowArea .list-step * {
  box-sizing: border-box;
}
.o-flowArea .list-step {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
  gap: 0;
}
@media (min-width: 769px) {
  .o-flowArea .list-step {
    flex-direction: row;
    gap: 24px;
  }
}
.o-flowArea .li-step {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  max-width: 400px;
  width: 100%;
}
.o-flowArea .tt-step {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.o-flowArea .step {
  color: var(--color-black, #1f1f1f);
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 100%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.o-flowArea ._1 {
  color: var(--color-black, #1f1f1f);
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 24px;
  line-height: 100%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.o-flowArea .btn-step {
  background: var(--color-keygold, #a59450);
  padding: 2px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 48px;
  position: relative;
}
.o-flowArea .inr {
  border-style: solid;
  border-color: var(--color-keybeige, #f0eeeb);
  border-width: 1px;
  padding: 7px 8px 7px 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.o-flowArea .div {
  color: var(--color-white, #ffffff);
  text-align: center;
  font-family: "HiraginoMinchoPro-W6", sans-serif;
  font-size: 18px;
  line-height: 140%;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.o-flowArea .polygon-3 {
  flex-shrink: 0;
  width: 18px;
  height: 32px;
  position: relative;
  overflow: visible;
  transform: rotate(90deg);
  margin-top: 8px;
}
@media (min-width: 769px) {
  .o-flowArea .polygon-3 {
    margin-top: 0;
    transform: rotate(0deg);
  }
}
.o-flowArea .btn-step2 {
  background: var(--color-gray999999, #999999);
  padding: 2px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 48px;
  position: relative;
}

.p-form-entry .li-step:nth-child(1) .btn-step2 {
  background: var(--color-keygold, #a59450);
}

.p-form-confirm .li-step:nth-child(3) .btn-step2 {
  background: var(--color-keygold, #a59450);
}

.p-form-complete .li-step:nth-child(5) .btn-step2 {
  background: var(--color-keygold, #a59450);
}