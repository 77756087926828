@use "css/mixin/app" as *;
// 「一括選択」button要素
.a-formButtonSelectInBulk {
  @include form-element-initialize-variables;
  @include initialize-form-component-typography;
  @include form-complete-checkboxButton-height-style;

  --color-secondary: var(--global-color-gray-400);
  --_bd-color: var(--color-secondary);
  --_outline-color: var(--_color);

  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid var(--_bd-color);
  border-radius: 1px;
  color: var(--_color);
  font-weight: 700;

  &:focus-visible {
    @include form-outline-initialize;
  }
}
