@use "css/mixin/app" as *;
// label maxw270
// input maxw376
.o-formStackUserAccount {
  --_inner-maxw: 376px; // NOTE ?
  padding: 40px 22px; // x is full 92px
  border: 2px solid var(--global-color-gray-400);
  border-radius: 2px;
  @include form-breakpoint-up {
    --_inner-maxw: 616px;
  }
}

.o-formStackUserAccount__inner {
  margin-inline: auto;
  max-width: var(--_inner-maxw, 100%);
}
