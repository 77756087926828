.o-formStackEncourageRegistration {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
}

.o-formStackEncourageRegistration__heading {
  font-weight: 700;
  text-align: center;
}

.o-formStackEncourageRegistration__actions {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
}

.o-formStackEncourageRegistration__forgotPassword {
  text-align: center;
}
