@use 'css/abstracts' as *;
// formStackは、レイアウトを行うためだけのスケルトンコンテナ
.o-formStack {
  --_space-y: 40px; // 24→40? 48px
  --_body-maxw: 640px;

  padding-top: 40px;

  // @include initialize-section-layout-styles;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
  "step"
  "header"
  "body"
  "footer";

  .o-formStackSection {
    & + .o-formStackSection {
      margin-top: 48px;
      @include form-breakpoint-up {
        margin-top: 80px;
      }
    }
  }

  .a-formHr {
    margin-block: var(--global-form-element-input-gap-x-3xl);
  }

  .o-formStackUserAccount {
    margin-inline: auto;
    max-width: 800px;
  }

  .o-formBlockPresentTheFlow {
    margin-inline: auto;
    max-width: 708px;
  }

  &.-bg-transparent {
    background-color: transparent;
  }

  &.-w-body-768 {
    @include form-breakpoint-up {
      --_body-maxw: 768px;
    }
  }
}

.o-formStack__step {
  grid-area: step;
  margin-inline: auto;
  margin-bottom: 24px;
  max-width: 768px;
  width: 100%;

  @include form-breakpoint-up {
    margin-bottom: 48px;
  }
}

.o-formStack__head {
  margin-inline: auto;
  margin-bottom: var(--_space-y);
  grid-area: header;
  max-width: var(--_body-maxw);
  width: 100%;

  .m-formBlockHelpMessage {
    margin-top: 24px;
  }

  @at-root {
    // h1 ページタイトルの場合
    .o-formStack__title {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: rem(20);
      line-height: var(--global-form-typofgraphy-lineHeight);
      font-weight: 700;

      @include form-breakpoint-up {
        font-size: rem(24);
      }

      & + * {
        margin-top: var(--_space-y);
        //@include form-breakpoint-up {
        //  margin-top: 80px;
        //}
      }
    }

    // h2 ページタイトルではない場合
    .o-formStack__heading {
      @include layout-max-content-x-center;
      font-size: 1rem;
      line-height: var(--global-form-typofgraphy-lineHeight);
      font-weight: 700;

      &:first-child {
        & + * {
          margin-top: var(--_space-y);
        }
      }

      & + .o-formStack__headDescription {
        margin-top: 8px;
      }

      &.-size-small {
        font-size: rem(14);
        @include form-breakpoint-up {
          font-size: rem(16);
        }
      }

      &.-size-large {
        font-size: rem(20);
        @include form-breakpoint-up {
          font-size: rem(24);
        }
      }
    }

    .o-formStack__headDescription {
      @include initialize-form-component-typography;
      @include layout-max-content-x-center;
    }
  }
}


.o-formStack__body {
  margin-inline: auto;
  grid-area: body;
  width: 100%;
}

.o-formStack__footer {
  grid-area: footer;

  margin-top: var(--_space-y);

  margin-inline: auto;
  max-width: 800px;
  width: 100%;
      @media (min-width: 769px){
         margin-top: 80px;
        }
}
