@use 'css/abstracts' as *;
// フォームブロックのコンテナー
.o-formBlockContainer {
  --_maxw: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: var(--_maxw, 100%);
  min-height: var(--global-form-element-min-height);
  height: auto;
   font-family: YuGothic,'Yu Gothic',sans-serif;

  @include formBlockContainer-modifier;

}
