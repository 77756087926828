@use 'css/vendors' as *;
@use 'css/mixin/app/form' as *;
// 要素としてプリミティブなコンポーネント
@use './3-design/lv1-atoms/formCheckbox';
@use './3-design/lv1-atoms/formCheckboxButton';
@use './3-design/lv1-atoms/formRowGroupTitle';
@use './3-design/lv1-atoms/formGroupTitle';
@use './3-design/lv1-atoms/formHr';
@use './3-design/lv1-atoms/formInput';
@use './3-design/lv1-atoms/formInputDateYear';
@use './3-design/lv1-atoms/formInputDateMonth';
@use './3-design/lv1-atoms/formInputDateDay';
@use './3-design/lv1-atoms/formInputFile';
@use './3-design/lv1-atoms/formInputZipCode';
@use './3-design/lv1-atoms/formLabel';
@use './3-design/lv1-atoms/formMarkOptional';
@use './3-design/lv1-atoms/formRadio';
@use './3-design/lv1-atoms/formSelect';
@use './3-design/lv1-atoms/formButtonSelectInBulk';
@use './3-design/lv1-atoms/formSuffixText';
@use './3-design/lv1-atoms/formTextarea';
@use './3-design/lv1-atoms/formTextFieldHint';
@use './3-design/lv1-atoms/formTextFieldWarning';
@use './3-design/lv1-atoms/formTextLinkAttention';
@use './3-design/lv1-atoms/formTextMessage';
@use './3-design/lv1-atoms/formTextPreview';
// コンテキストがあるコンポーネント
@use './3-design/lv2-molecules/formBlockBirthday';
@use './3-design/lv2-molecules/formBlockHelpMessage';
@use './3-design/lv2-molecules/formBlockInputIncome';
@use './3-design/lv2-molecules/formBlockInputZipCode';
@use './3-design/lv2-molecules/formBlockTextWarningMessage';
@use './3-design/lv2-molecules/formBlockWarningMessage';
@use './3-design/lv2-molecules/formListCheckbox';
@use './3-design/lv2-molecules/formListCheckboxButton';
@use './3-design/lv2-molecules/formListRadio';
@use './3-design/lv2-molecules/formSectionDescription';
@use './3-design/lv2-molecules/formSectionHeading';
// Blockは、レベル3以下の非Stackコンポーネントを組み合わせてドメインコンポーネントを作成する
// NOTE Blockはm、Blockの集合体はStack
@use './3-design/lv3-organisms/formBlockAccordionCheckbox';
@use './3-design/lv3-organisms/formBlockCheckboxButtonSelectInBulk';
@use './3-design/lv3-organisms/formBlockAction';
@use './3-design/lv3-organisms/formBlockAdditionalMessage';
@use './3-design/lv3-organisms/formBlockCheckboxButtonGroup';
@use './3-design/lv3-organisms/formBlockContainer';
@use './3-design/lv3-organisms/formBlockPreviewContainer';
@use './3-design/lv3-organisms/formBlockConsentToRegistration';
@use './3-design/lv3-organisms/formBlockInputFullName';
@use './3-design/lv3-organisms/formBlockPeriodOfAtime';
@use './3-design/lv3-organisms/formBlockPointMessage';
@use './3-design/lv3-organisms/formBlockPresentTheFlow';
@use './3-design/lv3-organisms/formBlockProgressStepBar';
// Stackは、block以下を配置する
@use './3-design/lv3-organisms/formStack';
@use './3-design/lv3-organisms/formStackGroup';
@use './3-design/lv3-organisms/formStackSection';
@use './3-design/lv3-organisms/formStackContainer';
@use './3-design/lv3-organisms/formStackEncourageRegistration';
@use './3-design/lv3-organisms/formStackUserAccount';
// Stackをレイアウトするためのコンテナ
//@use "./3-design/lv3-organisms/formContainer";
// Templates
@use './3-design/lv4-templates/form';

.o-formGlobalHeading {
	width: 100%;
	height: 260px;
	background: url(../img/form/bg_main.jpg) no-repeat center top;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	@media (min-width: 769px) {
		background: url(../img/form/bg_main_pc.jpg) no-repeat center top;
		background-size: cover;
	}
	& > div {
		width: 100%;
		max-width: 456px;
		padding: 0 24px;
	}
	h2 {
		color: var(--color-white, #fff);
		text-align: center;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 30px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 47.6px */
    @media (min-width: 769px) {
      font-size: 34px;
    }
	}
	span {
		margin-top: 10px;
		display: inline-flex;
		padding: 2px 20px 0px 20px;
    height: 24px;
		justify-content: center;
		align-items: center;
		background: var(--color-key_gold, #a59450);
		color: var(--color-white, #fff);
		text-align: center;
		font-family: Montserrat;
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 18px */
	}
	.-line {
		display: block;
		width: 100%;
		max-width: 360px;
		height: 4px;
		margin: 12px auto 0;
		border-radius: 1px;
		background: var(--green, #0a7b5c);
		// background: linear-gradient(
		// 	93.28deg,
		// 	#ae9553 1.75%,
		// 	#cfba83 23.2%,
		// 	#b99b4b 99.83%
		// );
		// box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
		// border-radius: 1px;
		overflow: hidden;
	}
}
.o-flowArea {
	.intro {
		font-weight: 400;
		line-height: 200%;
    margin-top: 24px;
    font-size: 14px;
    @media (min-width: 769px) {
      font-size: 15px;
      margin-top: 80px;
    }
	}
  .list-step,
.list-step * {
    box-sizing: border-box;
}

.list-step {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    flex-direction: column;
    gap: 0;
    @media (min-width: 769px) {
      flex-direction: row;
      gap: 24px;
    }

}

.li-step {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    position: relative;
    max-width: 400px;
    width: 100%;
}

.tt-step {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.step {
    color: var(--color-black, #1f1f1f);
    text-align: center;
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 16px;
    line-height: 100%;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

._1 {
    color: var(--color-black, #1f1f1f);
    text-align: center;
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 24px;
    line-height: 100%;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-step {
    background: var(--color-keygold, #a59450);
    padding: 2px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 48px;
    position: relative;
}

.inr {
    border-style: solid;
    border-color: var(--color-keybeige, #f0eeeb);
    border-width: 1px;
    padding: 7px 8px 7px 8px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex: 1;
    position: relative;
}

.div {
    color: var(--color-white, #ffffff);
    text-align: center;
    font-family: 'HiraginoMinchoPro-W6', sans-serif;
    font-size: 18px;
    line-height: 140%;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.polygon-3 {
    flex-shrink: 0;
    width: 18px;
    height: 32px;
    position: relative;
    overflow: visible;
    transform: rotate(90deg);
    margin-top: 8px;
    @media (min-width: 769px) {
      margin-top: 0;
      transform: rotate(0deg);
    }

}

.btn-step2 {
    background: var(--color-gray999999, #999999);
    padding: 2px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 48px;
    position: relative;

}


}
.p-form-entry .li-step:nth-child(1) .btn-step2 {
  background: var(--color-keygold, #a59450);
}
.p-form-confirm .li-step:nth-child(3) .btn-step2 {
  background: var(--color-keygold, #a59450);
}
.p-form-complete .li-step:nth-child(5) .btn-step2 {
  background: var(--color-keygold, #a59450);
}
// .o-flowWrap {
// 	display: flex;
// 	gap: 8px;
// 	@media (min-width: 769px) {
// 		gap: 24px;
// 	}
// 	li {
// 		flex: 1 1 33.333%;
// 		span {
// 			font-family: 'Albert Sans', sans-serif;
// 			font-weight: 400;
// 			font-size: 16px;
// 			line-height: 100%;
// 			color: #999;
// 			display: flex;
// 			align-items: center;
// 			.num {
// 				font-size: 24px;
// 				margin-top: -2px;
// 				margin-left: 5px;
// 			}
// 		}
// 		p {
// 			text-align: center;
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			height: 70px;
// 			color: #fff;
// 			background: #dfddd6;
// 			font-size: 18px;
// 			font-style: normal;
// 			font-weight: 700;
// 			line-height: 1.5;
// 			padding: 8px 0;
// 			@media (min-width: 769px) {
// 				height: 40px;
// 			}
// 		}
// 	}
// 	.p-form-entry & li:first-child p {
// 		background: #D10000;
// 		// border: 2px solid #7a7159;
// 	}
// 	.p-form-confirm & li:first-child p {
// 		// opacity: 0.6;
// 		background: #66dfbe;
// 	}
// 	.p-form-confirm & li:nth-child(2) p {
// 		background: #D10000;
// 		// border: 2px solid #7a7159;
// 	}
// 	.p-form-complete & li p {
// 		background: #66dfbe;
// 	}
// 	.p-form-complete & li:nth-child(3) p {
// 		background: #D10000;
// 		// border: 2px solid #7a7159;
// 	}
// }
