@use "css/mixin" as *;

.a-formRadio {
  --_box-size: 20px;
  @include form-element-initialize-variables;
  @include form-component-initialize-style;
  display: inline-flex;
  font-weight: 700;
  // 22/09/12 :has はFirefox103ではまだ使えない・・・
  // checkedの子要素を持っていたら、コンポーネントルートにCSS変数を設定できるように したかったな～
  // &:has(input:checked) {
  //  --color-primary: var(--global-color-warn);
  //  --color-secondary: var(--global-color-warn-contract);
  //}
}

.a-formRadio__input {
  @include form-element-initialize-variables;
  @include form-visibility-hidden;

  order: -1;


  &:checked {
    & + .a-formRadio__label {
      &::before {
        // background-color: var(--global-color-warn-contract);
      }
      &::after {
        opacity: 1;
      }
    }
  }
  &:focus-visible {
    & + .a-formRadio__label {
      &::before {
        @include form-outline-initialize;
      }
    }
  }
}

.a-formRadio__label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;

  &::before,
  &::after {
    content: "";
    border-radius: 50%;
    margin-top: -.2em;
  }

  &::before {
    // チェックボックスの箱
    border: 1px solid var(--color-gray_999999, #999);
    flex: 0 0 20px;
    min-width: 20px;
    width: 20px;
    height: 20px;
    background-color: var(--_bg-color);
  }

  &::after {
    // チェックマーク
    content: "";
    position: absolute;
    //top: calc((var(--_box-size) - 1em) / 2);
    // left: 0;
    // width: 20px;
    // height: 20px;
    width: 12px;
    height: 12px;
    // left: 4px;
    left: 5px;
    background-color: #D10000;
    // border: 2px solid  #7A7159;
    opacity: 0;
    box-sizing: border-box;
  }
  .p-form-confirm &::after {
        opacity: 1;
      }
}


