@use "css/vendors" as *;
@use "css/mixin/app/form" as *;

$for-full-header-breakpoint: 'xl';

// NOTE formモジュール限定かもしれない。そうならformへ移動しリファクタリングすること
@mixin initialize-section-layout-styles() {
  background-color: var(--global-color-white);
  padding: 40px 24px;
  @include form-breakpoint-up {
    padding: 64px 40px;
  }
}

@mixin layout-max-content-x-center {
  margin-inline: auto;
  max-width: max-content;
  width: 100%;
}

@mixin layout-site-header-full-breakpoint {
  @include mq($for-full-header-breakpoint) {
    @content;
  }
}

@mixin layout-site-header-full-breakpoint-less {
  @include mq($until: $for-full-header-breakpoint) {
    @content;
  }
}
