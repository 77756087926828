@use "css/mixin/app" as *;

.a-formTextarea {
  box-sizing: border-box;
  @include form-element-initialize-variables;
  @include form-component-initialize-style;
  @include form-input-component-initialize;
  @include form-focus-within;

  &:focus-visible {
    @include form-outline-initialize;
  }

  &::placeholder {
    font-weight: 700;
    color: var(--global-form-color-placeholder);
  }
}
